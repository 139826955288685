#products{
    padding: 96px 99px;
    gap: 44px;
    max-width: 1160px;
    background: url(../../../assets/images/HomeProductsBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 44px;
    margin-top: 44px;
}
#products .products-article-container{
    gap: 196px;
    width: 100%;
}
#products .products-article-container article h1{
    width: 194px;
    height: 58px;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #FFFFFF;    
}
#products .products-article-container article p{
    width: 460px;
    height: 26px;
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #FFFFFF;    
}
/* #products .products-article-container .products-article-container-button{
    padding: 8px 12px;
    gap: 10px;
    width: 90px;
    height: 42px;
    background: #FFFFFF;
    border-radius: 55px;
    border: none;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
} */
.products-content-container{
    padding: 0px;
    gap: 24px;
    width: 962px;
    height: 432px;
}
.products-content-cards{
    padding: 24px;
    gap: 24px;
    width: 304.67px;
    height: 432px;
    background: #FFFFFF;
    border-radius: 30px;    
    cursor: pointer;
    transition: background-color 0.6s ease, transform 0.6s ease; /* This line ensures smooth transition of the hover effect */
}
.products-content-cards:hover{
    background: #E0E0E0; 
    transform: scale(1.07); /* The div will grow by 10% when hovered */
}
.product-cards-imgContainer{
    width: 270px;
    height: 264px;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 10px;
    gap: 10px;
    width: 256.67px;
    height: 264px;
    background: #F9F9F9;
    border-radius: 24px;
    position: relative;
}/*
.product-card-img3{
    width: 264px;
    background-color: #F9F9F9;
    border-radius: 24px;

} */
.product-cards-imgContainer-btn{
    padding: 2px 10px;
    width: 126px;
    height: 30px;
    background: #E8EEFF;
    border-radius: 44px;
    border: none;
    cursor: default !important;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;

    position: absolute;
    top: 10px;
    right: 17px;
}
.products-content-cards article{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 10px;
    gap: 10px;
    
    width: 256.67px;
    height: 96px;    
}
.products-content-cards article h3{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;    
}
.bottomTri-markerLeft{
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    position: relative;
    right: 340px;
}
.bottomTri-markerMiddle{
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
}
.bottomTri-markerRight{
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    position: relative;
    left: 340px;
}
.productsExplanation-container{
    padding: 0px;
    gap: 24px;
    max-width: 962px;
    height: auto;
    border-radius: 30px;
    background-color: #FFFFFF;
    padding: 32px;
}
.productsExp-btn{
    position: absolute;
    top: 40px;
    right: 50px;
    border: 1px solid #E4E4E4;
    border-radius: 40px;
    background-color: transparent;
    width: 40px;
    height: 40px;
}
.productsExplanation-container h3{
    margin: 1.5rem;
}

/* Extra small devices */
@media (min-width: 320px) {
    #products{
        border-radius: 0;
        padding:  96px 10px;
        height: auto;
    }
    #products .products-article-container article p{
        width: 100%;
    }
    .products-content-container{
        width: 100%;
        height: auto;
        flex-direction: column !important;
        align-items: center !important;
    }
    .bottomTri-markerLeft, .bottomTri-markerRight, .bottomTri-markerMiddle{
        display: none !important;
    }
}

/* Small devices */
@media (min-width: 480px) {
    #products .products-article-container article p{
        width: 460px;
    }
}

/* Medium devices */
@media (min-width: 768px) {
    .products-content-container{
        flex-direction: row !important;
        justify-content: center !important;
        flex-wrap: wrap;
        
    }
    .products-content-cards {
        width: 304.67px; /* calc(50% - 12px) */
    }
}

/* Large devices */
@media (min-width: 992px) {
    #products{
        padding: 96px 99px;
        border-radius: 44px;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    .bottomTri-markerLeft, .bottomTri-markerRight, .bottomTri-markerMiddle{
        display: block !important;
    }
}
  