#whoWeAre{
    padding: 96px 99px;
    margin-top: 44px;
    gap: 44px;
    width: 1160px;
    height: 482px;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #E4E4E4;
    border-radius: 44px;
}
#whoWeAre .whoWeAre-content-container{
    gap: 196px;
}
.whoWeAre-content-container article{
    gap: 12px;
    width: 659px;
    height: 290px;
}
.whoWeAre-content-container article h1{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #000000;    
}
.whoWeAre-content-container article p{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;
}
.whoWeAre-btn{
    padding: 8px 12px;
    width: 107px;
    height: 42px;
    background: #000000;
    border-radius: 55px;    
    border: transparent;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;    
}

/* Extra small devices */
@media (min-width: 320px) {
    #whoWeAre{
        width: 99%;
        height: auto;
        padding: 96px 20px;
        position: relative;
    }
    #whoWeAre .whoWeAre-content-container{
        gap: 0px;
    }
    .whoWeAre-content-container article{
        width: 100%;
        height: auto;
    }
    .whoWeAre-btn{
        position: absolute;
        top: 45px;
        right: 30px;
    }
}

/* Medium devices */
@media (min-width: 768px) {
    .whoWeAre-content-container article{
        gap: 12px;
        width: 659px;
        height: 290px;
    }
    .whoWeAre-btn{
        position: absolute;
        top: 70px;
        right: 60px;
    }
}

/* Large devices */
@media (min-width: 992px) {
    .whoWeAre-btn{
        position: relative;
        top: 0px;
        right: 0px;
    }
    #whoWeAre .whoWeAre-content-container{
        gap: 60px;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    #whoWeAre{
        padding: 96px 99px;
        margin-top: 44px;
        gap: 44px;
        width: 1160px;
        height: 482px;
        background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        border: 1px solid #E4E4E4;
        border-radius: 44px;
    }
    #whoWeAre .whoWeAre-content-container{
        gap: 196px;
    }
    .whoWeAre-content-container article{
        gap: 12px;
        width: 659px;
        height: 290px;
    }
    .whoWeAre-content-container article h1{
        font-family: 'aeonik-500', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -0.01em;
        color: #000000;    
    }
    .whoWeAre-content-container article p{
        font-family: 'aeonik-400', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #6D6D6D;
    }
    .whoWeAre-btn{
        padding: 8px 12px;
        width: 107px;
        height: 42px;
        background: #000000;
        border-radius: 55px;    
        border: transparent;
    
        font-family: 'aeonik-500', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #FFFFFF;    
    }    
}
