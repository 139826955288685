#cultureAbout{
    margin-top: 44px;
    padding: 24px;
    gap: 16px;
    width: 766px;
    height: 630px;
}
#cultureAbout h1{
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;    
}
#cultureAbout h4{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #6D6D6D;
}
#cultureAbout p{
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: 0.01em;
    color: #6D6D6D;
}

/* Extra small devices */
@media (min-width: 320px) {
    #cultureAbout{
        width: 100%;
        height: auto;
    }
    #cultureAbout p{
        margin-top: 30px;
    }
}
/* Medium devices */
@media (min-width: 768px) {
    #cultureAbout{
        width: 766px;
        height: 630px;
    }
}