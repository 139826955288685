.worldMap{
    display: flex;
    justify-content: center;
    align-items: center;
    
    position: relative;

    height: 75vh;
    margin-top: 100px;
    margin-bottom: 50px;

    background-image: url(../images/WorldMap.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.imgeWorldMap{
    width: 100%;
    height: 650px;
}
.worldMap-btn{
    background: transparent;
    border: none;

    width: 42px;
    height: 42px;
}
.turkey-btn{
    position: relative;
    top: -50px;
    left: 33px;
}
.germany-btn{
    position: relative;
    top: -98px;
    left: 11px;
}
.africa-btn{
    position: relative;
    top: 110px;
    left: -4px;
}
.uk-btn{
    position: relative;
    top: -107px;
    left: 15px;
}
.pointerImg{
    width: 30px;
    height: 30px;
}
.pointerImg:hover{
    width: 42px;
    height: 42px;

}
.btn-primary{
    --bs-btn-hover-bg: transparent;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: transparent;
}
.Modal-Map{
    width: 778px;
    height: 500px;
}
.partner-logo img {
    border: 1px solid #cccccc;
    padding: 10px;
}