#subProducts{
    gap: 44px;
    width: 100%;
    height: auto;
    margin-top: 117px;
}
.header-subProducts{
    width: 100%;
    height: 148px;
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 54px;
}
#subProducts .header-subProducts .wrapper{
    gap: 116px;
    width: 1160px;
    height: 104px;    
}
#subProducts .header-subProducts .wrapper h1{
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #000000;
}
#subProducts .header-subProducts .wrapper p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;
}
#subProducts .wrapper{
    margin-bottom: 104px;
    position: relative;
}
.subContent{
    padding: 24px;
    gap: 44px;
    width: 1160px;
    height: 365px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 30px;
}
.subContentImg-container{
    padding: 44px 10px;
    gap: 10px;
    isolation: isolate;
    width: 534px;
    height: 317px;
    background: #F9F9F9;
    border-radius: 24px;
}
.subContent-article{
    gap: 24px;
    width: 534px;
    height: 317px;    
}
.subContent-btn{
    padding: 2px 10px;
    gap: 10px;
    position: absolute;
    width: 106px;
    height: 30px;
    left: 420px;
    top: 8px;
    background: #FFF2EB;
    border-radius: 44px;

    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FF8B4A;    
}
.subContent-articleHeader{
    width: 100%;
    height: 62px;
}
.subContent-article ul{
    gap: 10px;
    list-style: none;
    position: relative;
}
.subContent-article ul li::before{
    content: '✓';
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;    
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    background: #5182FF;
    border: 1px solid #5182FF;
    border-radius: 22px;
    position: absolute;
    left: 0px;
}
.subContent-articleBtn{
    background-color: #000000;
    padding: 8px 12px;
    width: 100px;
    height: 42px;
    border-radius: 55px;

    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    position: absolute;
    bottom: 24px;
}

/* Extra small devices */
@media (min-width: 320px) {
    #subProducts .header-subProducts .wrapper{
        flex-direction: column !important;
        justify-content: center !important;
        width: auto;
        height: auto;
        padding: 0 20px;
        gap: 30px;
    }
    .header-subProducts{
        height: auto;
    }
    .subContent{
        width: auto;
    }
    .subContent-article{
        width: auto;
    }
    .subContentImg-container{
        display: none !important;
    }
}

/* Medium devices */
@media (min-width: 1020px) {
    .subContentImg-container{
        display: flex !important;
    }
}