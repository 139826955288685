#innovation{
    padding: 96px 99px;
    gap: 44px;
    max-width: 1160px;
    height: 1022px;
    background: url(../../../assets/images/InnovationBg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #E4E4E4;
    border-radius: 44px;
    margin-bottom: 44px;
}
#innovation .header-article{
    width: 962px;
    height: 58px;    
}
#innovation article h1{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
#innovation .innovation-content{
    gap: 24px;
    width: 962px;
    height: 728px;    
}
.innovation-content-upperCard-container{
    gap: 24px;
    width: 962px;
    height: 250px;
}
.innovation-content-upperCard{
    padding: 44px;
    gap: 24px;
    width: 469px;
    height: 250px;
    background: #F9F9F9;
    border-radius: 30px;
}
.innovation-content-lowerCard-container{
    gap: 24px;
    width: 962px;
    height: 454px;
}
.innovation-content-lowerCard1{
    width: 287px;
    height: 448px;
    
    background: url(../../../assets/images/InnovationBrain.png);
    border-radius: 30px;
}
.innovation-content-lowerCard2{
    padding: 44px;
    gap: 32px;
    width: 651px;
    height: 454px;
    background: #FFFFFF;
    border-radius: 30px;
}
.innovation-content-lowerCard2 .links{
    width: 466px;
}
.innovation-active-btn{
    padding: 16px;
    width: 131px;
    height: 66px;
    border: none;
    background: transparent;
    border-bottom: 1px solid #5182FF;       
}
.innovation-passive-btn{
    padding: 16px;
    width: 131px;
    height: 66px;
    border: none;
    border-bottom: 1px solid #E4E4E4;
    background: transparent;
}
.innovation-content-lowerCard2 article{
    gap: 12px;
    width: 578px;
    height: 268px;
}
.innovation-content-lowerCard2 article ul{
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style: none;
}
.innovation-content-lowerCard2 article ul li{
    position: relative;
}
.innovation-leadForm{
    width: 100%;
    height: 100%;
    /* width: 555px;
    height: 300px; */
}
.innovation-content-lowerCard2 article ul li::before{
    content: url(../../../assets/images/orangeCheckmarks.svg);
    /* content: '✓'; */
    width: 20px;
    height: 20px;
    /* background-color: var(--mindTech-orange);
    border: 1px solid #FF8B4A; */
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: absolute;
    top: 5px;
    left: -32px;
    color: #FFFFFF;
}

/* Extra small devices */
@media (min-width: 320px) {
    #innovation{
        padding: 96px 20px;
        width: auto;
        height: auto;
        border-radius: 0;
    }
    #innovation .header-article{
        width: auto;
        height: auto;
    }
    #innovation .innovation-content{
        width: auto;
        height: auto;
    }
    .innovation-content-upperCard-container{
        width: auto;
        height: auto;
        flex-direction: column !important;
        align-items: center !important;
    }
    .innovation-content-upperCard {
        width: 320px;
        height: auto;
    }
    .innovation-content-lowerCard-container{
        justify-content: center !important;
        height: auto;
        width: 100%;
    }
    .innovation-content-lowerCard1{
        display: none;
    }
    .innovation-content-lowerCard2{
        width: 320px;
        height: auto;
    }
    .innovation-content-lowerCard2 article{
        width: auto;
        height: auto;
    }
    .innovation-leadForm{
        width: 100%;
        height: 350px;
    }
    .innovation-content-lowerCard2 .links{
        width: auto;
    }
    .innovation-passive-btn{
        width: auto;
    }
    .innovation-active-btn{
        width: auto;
    }
}

/* Small devices */
@media (min-width: 480px) {
    .innovation-content-upperCard {
        width: 480px;
        height: 250px;
    }
    .innovation-content-lowerCard2{
        width: 480px;
    }
    .innovation-leadForm{
        width: 100%;
        height: 540px;
    }
}

/* Medium devices */
@media (min-width: 768px) {
    .innovation-content-upperCard {
        width: 651px;
    }
    .innovation-content-lowerCard2{
        width: 651px;
    }
    .innovation-passive-btn{
        width: 131px;
    }
    .innovation-active-btn{
        width: 131px;
    }
}

/* Large devices */
@media (min-width: 992px) {
    #innovation{
        padding: 96px 20px;
        width: auto;
        height: auto;
        border-radius: 44px;
    }
    #innovation .header-article {
        width: 962px;
        height: 58px;
    }
    .innovation-content-upperCard-container{
        width: 962px;
        flex-direction: row !important;
    }
    .innovation-content-upperCard{
        width: 469px;
        height: 250px;
    }
    .innovation-content-lowerCard1{
        display: inline;
        height: 486px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
}

