#info-container{
    padding-top: 96px;
    display: flex;
    flex-direction: column;
    gap: 44px;
}
#info-container .info{
    gap: 44px;
}
.infoImg-container1{
    background-image: url(../../../assets/images/InfoHomeFirst.png);
    background-position: center;
    background-size: cover;
    width: 622px;
    height: 522px;
    border-radius: 44px;
    position: relative;
}
.infoHome-article1{
    padding: 24px;
    gap: 16px;
    
    max-width: 494px;
    max-height: 356px;
}
.infoArticle-button{
    padding: 2px 10px;
    gap: 10px;
    width: 154px;
    height: 30px;
    background: #E8EEFF;
    border-radius: 44px;
    border: none;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;
    cursor: default !important;
}
.infoHome-article1 h1{
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    max-width: 446px;
}
.infoHome-article1 p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;    
    max-width: 446px;
    height: 130px;
}
.infoHome-img1{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 24px;
    
    /* position: relative;
    left: 24px;
    top: 24px; */
    
    width: 227px;
    height: 112px;
    background: #FFFFFF;
    border-radius: 32px;
}

.infoHome-img1 h3{
    width: 91px;
    height: 32px;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;
}
.infoHome-article2{
    padding: 24px;
    gap: 16px;
    
    width: 494px;
    height: 356px;
    text-align: end;
}
.infoHome-article2 h1{
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    width: 446px;
}
.infoHome-article2 p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;    
    max-width: 446px;
    height: 130px;
}
.infoHome-img2{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 24px;
    
    position: absolute;
    right: 24px;
    bottom: 24px;
    
    width: 343px;
    height: 112px;
    background: #FFFFFF;
    border-radius: 32px;
}
.infoHome-img2 h3{
    width: 207px;
    height: 64px;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;    
}
.infoImg-container2{
    background-image: url(../../../assets/images/InfoHome-second.png);
    background-position: center;
    background-size: cover;
    width: 622px;
    height: 522px;
    border-radius: 44px;
}

/* Extra small devices */
@media (min-width: 320px) {
    #info-container .info{
        justify-content: center;
    }
    .infoImg-container1{
        display: none !important;
    }
    .infoImg-container2{
        display: none !important;
    }
    .infoHome-article1, .infoHome-article2{
        min-width: 320px !important;
        min-height: 542px !important;
    }
    .infoHome-article1 h1, .infoHome-article2 h1{
        width: auto !important;
        min-width: 285px !important;
    }
    .infoHome-article1 p, .infoHome-article2 p{
        width: auto !important;
        min-height: auto !important;
    }
}
/* Large devices */
@media (min-width: 992px) {
    #info-container .info{
        padding: 0 15px;
    }
    .infoImg-container1{
        display: flex !important;
    }
    .infoImg-container2{
        display: flex !important;
    }
}