#leadForm{
    width: 100%;
    height: 1000px;
    background-image: url(../images/HeaderHomeBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* padding: 182px 0; */
    padding: 70px 0 182px 0;
}
#leadForm .wrapper{
    padding: 64px;
    gap: 64px;

    /* width: 961px; */
    height: 641px;
    
    background: #FFFFFF;

    border: 1px solid #E4E4E4;
    border-radius: 30px;

    position: relative;
}
.leadForm-imgDiv{
    padding: 0px 99px;
    
    width: 280px;
    height: 413px;

    background: url(../images/LeadFormLogo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    
    border: 1px solid #E4E4E4;
    border-radius: 36px;
}
.leadForm-iFrame{
    width: 500px;
    height: 540px;
    position: relative;
    top: 20px;
}
.leadForm-h1{
    position: absolute;
    top: 13px;
    right: 360px;
}

/* Extra small devices */
@media (min-width: 320px) {
    #leadForm .wrapper{
        width: auto;
        padding: 64px 0;
    }
    .leadForm-imgDiv{
        display: none !important;
    }
    .leadForm-iFrame{
        width: 310px;
    }
    .leadForm-h1{
        top: 26px;
        left: 15px;
        right: 0;
    }
}

/* Small devices */
@media (min-width: 480px) {
    .leadForm-iFrame{
        width: 460px;
    }
}

/* Medium devices */
@media (min-width: 768px) {
    .leadForm-iFrame {
        width: 748px;
    }
}

/* Large devices */
@media (min-width: 992px) {
    #leadForm .wrapper{
        width: 961px;
        padding: 64px;
    }
    .leadForm-imgDiv{
        display: flex !important;
    }
    .leadForm-iFrame{
        width: 500px;
        height: 540px;
    }
    .leadForm-h1{
        top: 13px;
        right: 360px;
        left: auto;
    }

}

/* Extra large devices */
@media (min-width: 1200px) {

}
