#headerAbout{
    background-color: #FFFFFF;
    background-image: none;
    margin-top: 70px;
    height: auto;
    padding-top: 78px;
    gap: 44px;
}
.header-title article{
    gap: 116px;
    height: 174px;
}
.header-title article h1{
    width: 513px;
    height: 174px;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #000000;
}
.header-title article p{
    width: 531px;
    height: 104px;
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;
}
#headerAbout .header-image img{
    width: 1160px;
    height: 522px;
    border-radius: 44px;
}

/* Extra small devices */
@media (min-width: 320px) {
    #headerAbout{
        padding: 40px 20px 0 20px;
    }
    .header-title article{
        height: auto;
        gap: 10px;
        flex-direction: column !important;
    }
    .header-title article h1{
        width: auto;
        height: auto;
    }
    .header-title article p{
        width: auto;
        height: auto;
    }
    #headerAbout .header-image img{
        width: 300px;
        height: 135px;
    }
}

/* Small devices */
@media (min-width: 480px) {
    #headerAbout .header-image img{
        width: 440px;
        height: 198px;
    }
}

/* Medium devices */
@media (min-width: 768px) {
    #headerAbout .header-image img{
        width: 728px;
        height: 327.6px;
    }
}

/* Large devices */
@media (min-width: 992px) {

    #headerAbout .header-image img{
        width: 952px;
        height: 428.4px;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    #headerAbout{
        background-color: #FFFFFF;
        background-image: none;
        margin-top: 70px;
        height: auto;
        padding-top: 78px;
        gap: 44px;
    }
    .header-title article{
        flex-direction: row !important;
        gap: 116px;
        height: 174px;
    }
    .header-title article h1{
        width: 513px;
        height: 174px;
        font-family: 'aeonik-500', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -0.01em;
        color: #000000;
    }
    .header-title article p{
        width: 531px;
        height: 104px;
        font-family: 'aeonik-400', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #6D6D6D;
    }
    #headerAbout .header-image img{
        width: 1160px;
        height: 522px;
        border-radius: 44px;
    }
}
