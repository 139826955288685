footer{
    padding: 64px 140px 48px;
    gap: 64px;
    width: 100%;
    background: #F9F9F9;
    border-radius: 44px 44px 0px 0px;
    /* margin-top: 266px; */
    /* margin-top: 44px; */
}
footer .footer-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 24px;
    gap: 32px;
    width: 1160px;
    height: 103px;
    border-bottom: 1px solid #E4E4E4;    
}
.footer-content-container{
    gap: 32px;
    width: 1160px;
}
.footer-content{
    gap: 16px;
    width: 250px;
    height: 100%;
}
.footerContactDiv{
    position: relative;
    top: -11px;
}
.Footer-socialMedia{
    gap: 16px;
    width: 206.4px;
    height: 100%;   
}
.footer-content p{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;
}
.footer-content-link, .footer-content .btn, .footer-content a{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;
    position: relative;
    padding: 0 !important;
}
.phone-p{
    font-family: 'aeonik-400', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    letter-spacing: 0.01em !important;
    color: #000000 !important;
    position: relative !important;
    padding: 0 !important;
    margin: 0 !important;
}
.footer-mail::before{
    content: url(../../../assets/images/mailIcon.svg);
    position: absolute;
    top: 3px;
    left: -45px;
}
.footer-phone::before{
    content: url(../../../assets/images/phoneIcon.svg);
    position: absolute;
    top: 3px;
    left: -45px;
}
.footer-address::before{
    content: url(../../../assets/images/addressIcon.svg);
    position: absolute;
    top: 3px;
    left: -45px;
}

.social-icons{
    display: flex;
    flex-direction: row;
    gap: 13px;
}
.socialIcons-bottom{
    justify-content: center;
    width: 134px;
}
.navLink-new::after{
    content: 'New';
    padding: 2px 8px;
    width: 49px;
    height: 30px;
    background: #ECFDF3;
    mix-blend-mode: multiply;
    border-radius: 16px;
    color: #027A48;
    left: 8px;
    position: relative;
}
.footer-lower-container{
    padding: 32px 0px 0px;
    width: 1160px;
    height: 76px;
    border-top: 1px solid #E4E4E4;
}
.footer-lower-container p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;    
    margin: 0;
}
.tiktokLogo-footer{
    width: 36px;
    height: 36px;
}



/* Extra small devices */
@media (min-width: 320px) {
    footer{
        padding: 64px 20px 48px;
    }
    footer .footer-head{
        flex-direction: column;
        width: 100%;
    }
    .footer-content-container{
        flex-direction: column !important;
        align-items: center !important;
        width: 100%;
        gap: 50px;
    }
    .footer-lower-container{
        width: 100%;
        height: auto;
        flex-direction: column !important;
        gap: 20px;
    }
}

/* Small devices */
@media (min-width: 480px) {
    footer{
        padding: 64px 20px 48px;
    }
    footer .footer-head{
        align-items: start;
    }
    .footer-content-container{
        align-items: center !important;
        flex-direction: row !important;
        justify-content: center !important;
        flex-wrap: wrap;

    }
    .footer-lower-container{
        flex-direction: row !important;
        flex-wrap: wrap;
    }
}

/* Large devices */
@media (min-width: 1000px) {
    footer{
        padding: 64px 140px 48px;
    }
    .footer-content-container{
        justify-content: space-between !important;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {

}
