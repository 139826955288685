#pricingPlan{
    padding: 64px;
    gap: 44px;
    width: 1160px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 44px;    
    margin-top: 44px;
}
#pricingPlan .pricingP-content-container{
    gap: 24px;
    width: 100%;
} 
.pricingP-btn{
    padding: 8px 12px;
    width: 486px;
    height: 42px;
    background: #000000;
    border-radius: 55px;
    border: none;    

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;    
}
.pricingP-btn-container{
    padding: 4px;
    gap: 8px;
    width: 315px;
    height: 42px;
    background: #F9F9F9;
    border: 1px solid #E4E4E4;
    border-radius: 64px;
}
.pricingP-btn-container button{
    border: none;
    background: none;
    padding: 4px 2px;
    width: 93px;
    height: 34px;
    border-radius: 55px;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6D6D6D;
}
.pricingP-btn-container .pricingP-btn-active{
    background: #000000;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;    
}
.pricingP-content-container .pricingP-header-content{
    gap: 28px;
}
.pricingP-content-container form{
    gap: 12px;
}
.radio-input-container{
    padding: 32px 24px;
    isolation: isolate;
    width: 154px;
    /* height: 220px; */
    background: #F9F9F9;
    border-radius: 20px;    
    cursor: pointer;
}
/* .radio-input-active-container{
    padding: 32px 24px;
    isolation: isolate;
    width: 154px;
    height: 220px;
    background: #F9F9F9;
    border-radius: 20px;    
    cursor: pointer;
    background-image: url(../../../assets/images/radio-input-active-containerBG.png);
    background-repeat: no-repeat;
    background-size: cover;
} */
.activve {
    background-image: url(../../../assets/images/radio-input-active-containerBG.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.inactivve {
    background: #F9F9F9;
}
.activeText{
    color: #FFFFFF !important;
}
.inactiveText{
    color: #000000 !important;
}
.radio-input-container input[type="radio"], .radio-input-active-container input[type="radio"] {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 6px;
    top: 6px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 44px;
}
.package-name-btn{
    padding: 2px 10px;
    background: #E8EEFF;
    border-radius: 44px;
    border: none;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;
    cursor: default !important;
}
.radio-input-container article, .radio-input-active-container article{
    gap: 12px;
    width: 106px;
    /* height: 144px;     */
}
.radio-input-container article p{
    width: 106px;
    height: 52px;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #101828;    
}
.radio-input-container article h2{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
}
.radio-input-active-container article p{
    width: 106px;
    height: 52px;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;    
}
.radio-input-active-container article h2{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.plans-feature-container{
    width: 1032px;
}
.plans-feature-container ul li{
    width: 1022px;
    border-bottom: 1px solid #E4E4E4;
}
.plans-feature-container ul li article{
    padding: 10px;
}
.plans-feature-container ul li article p{
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #475467; 
    margin: 0;  
}
.pros-cons{
    gap: 12px;
    width: 652px;
}
.prosCons-img-container{
    padding: 10px 0px;
    width: 154px;
    height: 40px;    
}

/* Extra small devices */
@media (min-width: 320px) {
    #pricingPlan{
        padding: 64px 0px 64px 5px;
        width: auto;
        height: auto;
        align-items: center !important;
    }
    .pricingP-content-container .pricingP-header-content{
        align-items: center !important;
    }
    #pricingPlan .pricingP-content-container{
        flex-direction: column !important;
    }
    .pricingP-btn-container{
        width: auto;
        gap: 3px;
    }
    .pricingP-content-container form{
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .plans-feature-container{
        display: none !important;
    }
    .pricingP-btn{
        width: 300px;
    }
}

/* Small devices */
@media (min-width: 480px) {
    #pricingPlan{
        padding: 64px;
    }
    .pricingP-content-container .pricingP-header-content{
        align-items: start !important;
    }
}

/* Medium devices */
@media (min-width: 800px) {
    .pricingP-btn{
        width: 486px;
    }
}

/* Large devices */
@media (min-width: 992px) {
    #pricingPlan .pricingP-content-container{
        flex-direction: row !important;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    #pricingPlan{
        align-items: end !important;
    }
    .plans-feature-container{
        display: flex !important;
    }    
    .pricingP-btn{
        width: 486px;
    }
}
