/* Fonts */
@font-face {
    font-family: 'aeonik-400';
    src: url(./assets/fonts/Aeonik-Regular.otf) format('otf');
    font-weight: 400;
    font-style: normal;
}
  
@font-face {
    font-family: 'aeonik-500';
    src: url(./assets/fonts/Aeonik-Medium.otf) format('otf');
    font-weight: 500;
    font-style: normal;
}
  
@font-face {
    font-family: 'aeonik-700';
    src: url(./assets/fonts/Aeonik-Bold.otf) format('otf');
    font-weight: 700;
    font-style: normal;
}
/* Colors */
:root{
    --mindTech-blue: #5182FF;
    --mindTech-orange: #FF8B4A;
    --mindTech-gray: #F9F9F9;
    /* Black */
    /* White */
    --mindTech-linear-color: linear-gradient(198deg, #1D2B4E 7.24%, #5A78C4 47.65%, #A4BAF3 90.68%);
}

*, *::before, *::after{
    box-sizing: border-box;
    margin: 0;
}

html{
    scroll-behavior: smooth;
    box-sizing: border-box;
}

body{
    margin: 0;
    box-sizing: border-box;
    /* overflow-x: hidden !important; */
}

.wrapper{
    max-width: 1160px;
    margin: 0 auto;
}

a{
    text-decoration: none;
}

h1{
    font-family: 'aeonik-500', sans-serif;
    font-weight: 500;
    font-style: normal;
}

p{
    font-family: 'aeonik-400', sans-serif;
    font-weight: 400;
    font-style: normal;
}
.proComp{
    padding-top: 500px;
}
.gmStyleIw {
    max-width: 200px !important;
}