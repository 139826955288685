#headerDiagnostic{
    background-color: #FFFFFF;
    background-image: none;
    margin-top: 70px;
    height: auto;
    padding-top: 78px;
    gap: 44px;
}
#headerDiagnostic article{
    width: 100%;
}
#headerDiagnostic article h1{
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #000000;
}
.headerDiagnosticArticleP{
    width: 531px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;    
    margin: 0;
}
.breakline-diagnostic{
    width: 100%;
    height: 1px;
    background: #E4E4E4;
}
.embed-ytb{
    width: 800px;
    height: 450px;
}
.headerDiagnostic-options{
    padding: 10px;
    width: 195px;
    height: 172px;    
}
.optionsBtn-passiveDiagnostic{
    background: transparent;
    border: none;
    border-left: 2px solid #E4E4E4;
    padding: 6px 16px;
    width: 175px;
    height: auto !important;
    text-align: start;
}
.optionsBtn-activeDiagnostic{
    background: transparent;
    border: none;
    border-left: 2px solid #E4E4E4;
    padding: 6px 16px;
    width: 175px;
    height: 38px;
    text-align: start;
    border-left: 2px solid #5182FF;
}
.headerDiagnostic-content{
    gap: 32px;
    width: 922px;
    height: auto;    
}
.articleLeft{
    padding: 32px;
    gap: 16px;
    width: 284px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 32px;
}
.articleRightBrainScan{
    padding: 32px;
    gap: 16px;
    width: 606px;
    height: 394px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 32px;
}
.articleLeft h1, .articleRightBrainScan h1{
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;    
}
.articleLeft p, .articleRightBrainScan p{

    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;
}
.diagnosticArticle2{
    padding: 32px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns, taking up equal space */
    grid-gap: 20px; /* 20px gap between columns */
}
.diagnosticArticle3{
    width: 620px;
    padding: 32px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns, taking up equal space */
    grid-gap: 20px; /* 20px gap between columns */
}
.diagnosticArticle4{
    padding: 32px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns, taking up equal space */
    grid-gap: 20px; /* 20px gap between columns */
}
.howItWorksOL-twoColumn{
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    column-gap: 20px;
    -webkit-column-gap: 20px; /* Chrome, Safari, Opera */
    -moz-column-gap: 20px; /* Firefox */
}
.conditionsUL{
    gap: 20px;
}
.cognifitTests{
    background-color: #000000;   
    border: none;
    padding: 8px 12px;
    width: 111px;
    height: 42px;
    border-radius: 55px;
    position: relative;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;

}
.cognifitTests::after{
    content: 'Free';
    padding: 2px 10px;
    width: 49px;
    height: 30px;
    background: #E8EEFF;
    border-radius: 44px;
    position: absolute;
    right: -65px;
    bottom: 6px;
    
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;
}
.modal-titleTests{
    position: relative;
}
.modal-titleTests::after{
    content: 'Free';
    padding: 2px 10px;
    width: 49px;
    height: 30px;
    background: #E8EEFF;
    border-radius: 44px;
    position: absolute;
    right: -55px;
    bottom: 0px;
    
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;   
}
.modal-body{
    padding: 10px !important;
}
.modal-xl{
    max-width: 1368px !important;
}
.btn-close{
    border: 1px solid #E4E4E4;
    border-radius: 55px;
}
.modal-body-content{
    display: flex !important;
    flex-direction: row !important;
    gap: 48px;
    width: 100%;
}
.assesment-container{
    padding: 0px;
    gap: 12px;
    width: 50%;
    height: auto;
}
.assesment-container .assesment{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-bottom: 1px solid #E4E4E4;
    width: 100%;
}
.assesmentImg{
    width: 48px;
    height: 48px;
}
.assesment-btnContainer{
    display: flex !important;
    flex-direction: row !important;
    gap: 12px;
}
.learnMore-btn{
    padding: 2px 10px;
    min-width: 105px;
    height: 30px;
    border: 1px solid #E4E4E4;
    border-radius: 55px;    
    background: transparent;
    color: #000000;
}
.start-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    width: 51px;
    height: 30px;
    background: #000000;
    border-radius: 55px;
    border: none;
    color: #FFFFFF;
}
.assesmentArticle{
    padding: 0px;
    width: 394px !important;
    height: auto !important;
}
.assesmentArticle h5{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;
    width: auto !important;
    height: auto !important;
}
.assesmentArticle p{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;
    width: auto !important;
    height: auto !important;
}


/* Extra small devices */
@media (min-width: 320px) {
    #headerDiagnostic{
        padding: 78px 20px 0 20px;
    }
    #headerDiagnostic article{
        flex-direction: column !important;
        gap: 20px;
    }
    .headerDiagnosticArticleP{
        width: auto !important;
    }
    .headerDiagnostic-content-container{
        flex-direction: column !important;
        gap: 20px;
    }
    .headerDiagnostic-content{
        flex-direction: column !important;
        width: auto !important;
        height: auto !important;
        align-items: center !important;
    }
    .articleRightBrainScan{
        width: 284px;
        height: auto;
    }
    .diagnosticArticle2{
        width: 100%;
    }
    .howItWorksOL-twoColumn{
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        column-gap: 50px;
        -webkit-column-gap: 20px; /* Chrome, Safari, Opera */
        -moz-column-gap: 20px; /* Firefox */
    }
    .diagnosticArticle3{
        width: auto;
        height: auto;
    }
    .conditionsUL{
        gap: 0;
    }
    .modal-body-content{
        flex-direction: column !important;
        align-items: center !important;
    }
    .assesment-container{
        width: 100% !important;
    }
    .assesment-btnContainer{
        flex-direction: column !important;
    }

    .embed-ytb{
        width: 300px;
        height: 169px;
    }
}

/* Small devices */
@media (min-width: 480px) {
    .articleRightBrainScan{
        width: 444px;
        height: auto;
    }
    .conditionsUL{
        gap: 20px;
    }
    .assesment-btnContainer{
        flex-direction: row !important;
    }

    .embed-ytb{
        width: 460px;
        height: 259px;
    }
}

/* Medium devices */
@media (min-width: 768px) {
    .articleRightBrainScan{
        width: 606px;
        height: auto;
    }
    .howItWorksOL-twoColumn{
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        column-gap: 20px;
        -webkit-column-gap: 20px; /* Chrome, Safari, Opera */
        -moz-column-gap: 20px; /* Firefox */
    }
    
    .embed-ytb{
        width: 750px;
        height: 422px;
    }
}

/* Large devices */
@media (min-width: 992px) {
    .headerDiagnostic-content{
        flex-direction: row !important;
        align-items: start !important;
        gap: 32px;
        width: 922px;
        height: auto;    
    }
    .embed-ytb{
        width: 800px;
        height: 450px;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    #headerDiagnostic{
        padding: 78px 0 0 0;
    }
    #headerDiagnostic article{
        flex-direction: row !important;
        gap: 0px;
    }
    .headerDiagnosticArticleP{
        width: 531px !important;
    }
    .headerDiagnostic-content-container{
        flex-direction: row !important;
    }
}
@media (min-width: 1368px) {
    .modal-body-content{
        flex-direction: row !important;
    }
    .assesment-container{
        width: 50% !important;
    }
}

