#headerDiagnostic{
    background-color: #FFFFFF;
    background-image: none;
    margin-top: 70px;
    height: auto;
    padding-top: 78px;
    gap: 44px;
}
#headerDiagnostic article{
    width: 100%;
}
#headerDiagnostic article h1{
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #000000;
}
.headerDiagnosticArticleP{
    width: 531px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;    
    margin: 0;
}
.breakline-diagnostic{
    width: 100%;
    height: 1px;
    background: #E4E4E4;
}
.headerTreatments-options{
    padding: 10px;
    width: 513px;
    height: 302px;
}
.headerTreatments-options .optionsBtn-passive{
    background: transparent;
    border: none;
    border-left: 2px solid #E4E4E4;
    padding: 6px 16px;
    width: 493px;
    height: 38px;
    text-align: start;
    position: relative;
}
.optionsBtn-active{
    background: transparent;
    border: none;
    padding: 6px 16px;
    width: 493px;
    height: 38px;
    text-align: start;
    position: relative;
    border-left: 2px solid #5182FF;
}
.options-button3::after, .options-button7::after{
    content: 'Hybrid';
    padding: 2px 10px;
    width: 63px;
    height: 30px;
    background: #E8EEFF;
    border-radius: 44px;    
    position: relative;
    left: 10px;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;
}
.options-button3::before{
    content: 'Home Based';
    padding: 2px 10px;
    
    background: #FFF2EB;
    border-radius: 44px;    
    position: absolute;

    width: 103px;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FF8B4A;
    left: 188px;
    top: 4px;
}
.options-button5::after{
    content: 'Clinic';
    padding: 2px 10px;
    background: #DEFFF1;
    border-radius: 44px;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #3BC78C;

    position: relative;
    left: 10px;
}
.options-button7::before{
    content: 'Home Based';
    padding: 2px 10px;
    
    background: #FFF2EB;
    border-radius: 44px;    
    position: absolute;

    width: 104px;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FF8B4A;
    left: 285px;
    top: 4px;
}
.options-button4::after{
    content: 'Home Based';
    padding: 2px 10px;
    width: 104px;
    height: 30px;
    
    background: #FFF2EB;
    border-radius: 44px;    

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FF8B4A;
    position: relative;
    left: 10px;
}
.headerTreatments-content, .headerTreatments-content2-7{
    max-width: 647px;
    height: auto;
}
.headerTreatments-content p, .headerTreatments-content2-7 p, .headerTreatments-content ul li, .headerTreatments-content2-7 ul li{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;
    margin: 0;
}
.headerTreatments-content2-7 p{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #6D6D6D;

    margin: 0;
}
.headerTreatments-content2-7 p b{
    color: #000000;
}
.headerTreatments-content2-7 h5{
    margin: 2rem;
}
.headerTreatments-content h3, .headerTreatments-content2-7 h3{
    margin-bottom: 2rem;
}

/* Extra small devices */
@media (min-width: 320px) {
    .headerTreatments-options{
        width: auto;
        height: auto;
    }
    .optionsBtn-active{
        width: auto;
        height: auto;
    }
    .headerTreatments-options .optionsBtn-passive{
        width: auto;
        height: auto;
    }
    .options-button3::before{
        display: none;
    }
    .options-button4::after{
        display: none;
    }
    .options-button7::before{
        display: none;
    }
    .headerTreatments-content, .headerTreatments-content2-7{
        width: auto;
        height: auto;
    }
    .headerTreatments-content2-7 p{
        width: auto;
        height: auto;
    }
}

/* Small devices */
@media (min-width: 480px) {
    .options-button3::before{
        display: inline-block;
    }
    .options-button4::after{
        display: inline-block;
    }
    .options-button7::before{
        display: inline-block;
    }
}