header{
    width: 100%;
    height: 100vh;
    background-image: url(../../../assets/images/HeaderHomeBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    padding-top: 110px;
}
#headerHome{
    width: auto;
    height: 712px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 82px 0 140px;
}

#headerHome article{
    width: auto;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;    
}

#headerHome article h1{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 74px;
    line-height: 86px;
    letter-spacing: -0.01em;
    color: #FFFFFF;    
}

#headerHome article p{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #FFFFFF;    
}

#headerHome article ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 493px;
    height: auto;    
    margin-left: 32px;
    list-style: none;
}

#headerHome article ul li{
    width: 461px;
    height: 52px;
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    position: relative;
}
#headerHome article ul li::before{
    content: url(../../../assets/images/orangeCheckmarks.svg);
    /* content: '✓'; */

    width: 20px;
    height: 20px;
    /* background-color: var(--mindTech-orange); */
    /* border: 1px solid #FF8B4A; */
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: absolute;
    top: 6px;
    left: -32px;
}

.navbarHeader-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    width: 117px;
    height: 42px;
    background: #FFFFFF;
    border-radius: 55px;
    position: relative;
    bottom: 20px;

    color: #000000;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    text-decoration: none;
}

.header-blackSpace{
    width: 100%;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: black;
    bottom: 0;
    left: 0;
}
.header-blackSpace button{
    position: absolute;
    top: -53px;
    color: #FFFFFF !important;
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    border: none;
    background: transparent;
}
.header-blackSpace button::after{
    content: url(../../../assets/images/ScrollMore.svg);
    width: 14px;
    height: 7px;
    position: absolute;
    left: 57px;
    bottom: 0;
}
.header-blackSpace h3{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
}

/* Extra small devices */
@media (min-width: 320px) {
    header{
        width: 100%;
        height: 114vh;
        /* padding-top: 170px; */
    }
    #headerHome{
        width: calc(100% - 40px);
        margin: 0;
        padding: 0 10px;
        justify-content: center;
    }
    .image-container{
        display: none;
    }
    #headerHome article{
        width: 320px;
        margin-top: 50px;
        padding-bottom: 20px;
        position: relative;
        left: 12px;
    }
    #headerHome article h1{ 
        font-size: 35px;
        line-height: 40px;
    }
    #headerHome article p{
        width: 280px;
    } 
    #headerHome article ul{
        margin-left: 10px;
        width: 277px;
    }
    #headerHome article ul li{
        width: 277px;
        height: auto;
    }
    .header-blackSpace{
        height: 85px;
    }
    .header-blackSpace h3{
        font-size: 25px;
    }
}
  
/* Small devices */
@media (min-width: 460px) {
    header{
        width: 100%;
        height: 117vh;
    }
    #headerHome article{
        width: 100%;
        margin-top: 0px;
        padding-bottom: 20px;
    }
    #headerHome article h1{ 
        font-size: 38px;
        line-height: 45px;
    }
    #headerHome article p{
        width: 100%;
    } 
    #headerHome article ul{
        margin-left: 32px;
        width: 90%;

    }
    #headerHome article ul li{
        width: 100%;
        height: auto;
    }
    .header-blackSpace{
        height: 70px;
    }
    .header-blackSpace h3{
        font-size: 29px;
    }
}
  
/* Medium devices */
@media (min-width: 768px) {
    #headerHome{
        justify-content: space-between;
    }
    #headerHome article{
        width: 51%;
        margin-top: 0px;
        padding-bottom: 20px;
    }
    #headerHome article h1{
        font-size: 48px;
        line-height: 55px;
    }
    .image-container{
        display: block;
        position: relative;
        left: 20px;
        bottom: 20px;
    }
    .image-container img{
        width: 350px;
        height: 350px;
    }
    #headerHome article ul li{
        height: auto;
    }
}

/* Large devices */
@media (min-width: 890px) {
    .image-container{
        left: 20px;
        bottom: -28px;
    }
    .image-container img{
        width: 450px;
        height: 450px;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    #headerHome{
        margin: 0 82px 0 140px;
    }
    #headerHome article{
        width: auto;
        height: auto;
        margin-top: auto;
    }
    #headerHome article h1{
        font-size: 59px;
        line-height: 64px;
    }
    .image-container img{
        height: auto;
        width: auto;
        bottom: 0;
        left: 0;
    }

}
@media (min-width: 1200px){
header{
    height: auto !important;
    padding-bottom: 140px !important;
}
}
@media (min-width: 1368px){
    header{
        width: 100%;
        height: auto !important;
        padding-bottom: 110px !important;
    }
}

@media (min-width: 1450px) {
    #headerHome article h1{
        font-size: 74px;
        line-height: 86px;
    }
}
