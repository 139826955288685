.locations {
    width: 100%;
    float: left;
    cursor: pointer;
}

.locationsRow {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 20px;
    padding-top: 10px;
}

.locations .locationName {
    font-size: 18px;
    font-weight: bold;
}

.locations .locationAddress {
    min-height: 60px;
}
.markerLable {
    margin-top: 70px;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: bold;
    background: #212529;
    color: #ffffff !important;
}
.locationsLeft {
    max-height: 80vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.locationsLeft::-webkit-scrollbar{
    display: none;
}

.locationButton {
    margin-top: 10px;
}

.locationButton a {
    border-radius: 16px !important;
    padding-left: 15px;
    padding-bottom: 6px;
}

.infowindowName {
    font-weight: bold;
    font-size: 18px;
}
.search-box{
    width: 100%;
    float: left;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 50px;
    max-width: 728px;
    position: relative;
    padding: 15px 25px;
}

.search-box label{
    width: 100%;
    float: left;
    font-weight: 700;
    font-size: 12px;
    padding: 0;
    margin: 0;
}

.textbox-row{
    width: 100%;
    float: left;
    position: relative;
    padding: 0 0 0 30px;
}

.textbox-row i{
    width: auto;
    float: left;
    position: absolute;
    left: 0;
    top: 0;
}
.textbox-row input{
    width: 100%;
    float: left;
    border: none;
    font-size: 16px;
    padding: 0 0 8px;
    text-decoration: underline;
    font-weight: 600;
}
.textbox-row input:focus{
    border: none;
    box-shadow: none;
}
.textbox-row input:focus::placeholder {
    opacity: 0;
}

@media (max-width: 1180px) {
    .locationWrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
}
