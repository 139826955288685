.PricingPlan{
    padding: 64px;
    gap: 44px;
    width: 1160px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 44px;    
    margin-top: 44px;
}
.pricingP_content_container{
    gap: 24px;
    width: 100%;
}
.pricingP_btn{
    padding: 8px 12px;
    width: 486px;
    height: 42px;
    background: #000000;
    border-radius: 55px;
    border: none;    

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;    
}
.pricingP_header_content{
    gap: 28px;
}
.pricingP_content_container form{
    gap: 12px;
}
.radio_input_container{
    padding: 32px 24px;
    isolation: isolate;
    width: 180px !important;
    height: 257px;
    background: #F9F9F9;
    border-radius: 20px;    
    cursor: pointer;
}
.redioInput{
    position: absolute;
    width: 24px;
    height: 24px;
    right: 6px;
    top: 6px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 44px;
}
.package_name_btn{
    padding: 2px 10px;
    background: #E8EEFF;
    border-radius: 44px;
    border: none;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;
    cursor: default !important;
}
.active {
    background-image: url(../../../assets/images/radio-input-active-containerBG.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.inactive {
    background: #F9F9F9;
}
.activeText{
    color: #FFFFFF !important;
    margin-top: 25px;
    height: 35px;
    display: flex;
    align-items: center;
}
.inactiveText{
    color: #000000 !important;
    margin-top: 35px;
    height: 35px;
    display: flex;
    align-items: center;
}
.radio_input_container article{
    gap: 12px;
    width: 100% !important;
    /* height: 144px;     */
}

.pricingRow {
    width: 100%;
    border-bottom: 1px solid #E4E4E4;
    float: left;
    padding: 10px 15px;
}

.plans_feature_container{
    width: 1032px;
}
.plans_feature_container ul li{
    width: 1022px;
    border-bottom: 1px solid #E4E4E4;
}
.plans_feature_container ul li article{
    padding: 10px;
}
.plans_feature_container ul li article p{
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #475467; 
    margin: 0;  
}
.pros_cons{
    gap: 12px;
    width: 652px;
}
.prosCons_img_container{
    padding: 10px 0px;
    width: 154px;
    height: 40px;    
}

/* Extra small devices */
@media (min-width: 320px) {
    .PricingPlan{
        margin-top: 45px !important;
        padding: 64px 0px 64px 5px;
        width: auto;
        height: auto;
        align-items: center !important;
    }
    .pricingP_content_container{
        flex-direction: column !important;
    }
    .pricingP_content_container .pricingP_header_content{
        align-items: center !important;
    }
    .pricingPlan .pricingP_content_container{
        flex-direction: column !important;
    }
    .pricingP_btn_container{
        width: auto;
        gap: 3px;
    }
    .pricingP_content_container form{
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .plans_feature_container{
        display: none !important;
    }
    .pricingP_btn{
        width: 300px;
    }
}

/* Small devices */
@media (min-width: 480px) {
    .PricingPlan{
        padding: 64px;
    }
    .pricingP_content_container{
        flex-direction: row !important;
    }
    .pricingP_content_container .pricingP_header_content{
        align-items: start !important;
    }
}

/* Medium devices */
@media (min-width: 800px) {
    .pricingP_btn{
        width: 486px;
    }
}

/* Large devices */
@media (min-width: 992px) {
    .pricingP_content_container{
        flex-direction: row !important;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    .pricingPlan{
        align-items: end !important;
    }
    .plans_feature_container{
        display: flex !important;
    }    
    .pricingP_btn{
        width: 486px;
    }
}
