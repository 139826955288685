#partnersLogo{
    margin-top: 140px;
    padding: 0px 0px 96px;
    gap: 68px;
    
    max-width: 738px;
    height: 266px;    
}
.PartnersContent{
    gap: 56px;
}

/* Extra small devices */
@media (min-width: 320px) {
    #partnersLogo{
        padding: 0px 20px 96px 20px;
        height: auto;    

    }
    .PartnersContent{
        flex-direction: column !important;
        gap: 56px;
    }
}

/* Small devices */
@media (min-width: 480px) {

}

/* Medium devices */
@media (min-width: 768px) {
    #partnersLogo{
        margin-top: 140px;
        padding: 0px 0px 96px;
        gap: 68px;
        
        width: 738px;
        height: 266px;    
    }
    .PartnersContent{
        flex-direction: row !important;
        gap: 56px;
    }
}

/* Large devices */
@media (min-width: 992px) {

}

/* Extra large devices */
@media (min-width: 1200px) {

}
