#clinicAbout{
    padding: 96px 99px;
    gap: 44px;
    
    width: 1160px;
    height: 604px;
    margin-top: 44px;
    position: relative;
}
#clinicAbout article{
    width: 962px;
    height: 96px;
}
#clinicAbout article p{
    color: #6D6D6D;
}
.clinicAbout-contentDiv{
    gap: 24px;
    width: 962px;
    height: 272px;
}
.clinicAbout-Content{
    padding: 24px;
    gap: 24px;
    width: 173.2px;
    height: 272px;
    background: #F9F9F9;
    border-radius: 30px;    
}
.clinicAbout-Content p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;    
}
.clinicAbout-Content1::before{
    content: '1';
    width: 44px;
    height: 44px;
    background: #000000;
    border-radius: 44px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.clinicAbout-Content2::before{
    content: '2';
    width: 44px;
    height: 44px;
    background: #000000;
    border-radius: 44px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.clinicAbout-Content3::before{
    content: '3';
    width: 44px;
    height: 44px;
    background: #000000;
    border-radius: 44px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.clinicAbout-Content4::before{
    content: '4';
    width: 44px;
    height: 44px;
    background: #000000;
    border-radius: 44px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.clinicAbout-Content5::before{
    content: '5';
    width: 44px;
    height: 44px;
    background: #000000;
    border-radius: 44px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.clinicAbout-btn{
    padding: 8px 12px;
    width: 173px;
    height: 42px;
    background: #000000;
    border-radius: 55px;
    border: none;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    position: absolute;
    top: 111px;
    right: 99px;
}

/* Extra small devices */
@media (min-width: 320px) {
    #clinicAbout{
        padding: 0 20px 50px 20px;
        width: auto;
        height: auto;
    }
    #clinicAbout article{
        width: 100%;
        height: auto;
    }
    .clinicAbout-btn{
        width: 80%;
        position: relative;
        top: auto;
        right: auto;
    }
    .clinicAbout-contentDiv{
        width: auto;
        height: auto;
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center !important;
        margin-top: 50px;
    }
}
/* Medium devices */
@media (min-width: 768px) {
    .clinicAbout-btn{
        width: 173px;
        position: absolute;
        top: 27px;
        right: 20px;
    }
    .clinicAbout-contentDiv{
        margin-top: 0;
    }

}

/* Large devices */
@media (min-width: 992px) {
    .clinicAbout-btn{
        top: 31px;
        right: 55px;
    }
    #clinicAbout article {
        width: 962px;
        height: 96px;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    .clinicAbout-btn{
        top: 31.5px;
        right: 100px;
    }
}


