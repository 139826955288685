nav{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 100px;
    gap: 261px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #343974;
    z-index: 300;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000000;
}
.desktopVOf-navbar{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* max-width: 1160px; */
}
nav ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;    
    list-style: none;
    gap: 32px;
    list-style: none;
    margin: 0;
}
nav ul li{
    color: white;
    display: inline;
}
nav ul .nav-about{
    width: 68px;
}
nav .btn{
    color: white;
}
nav .btn:hover, .btn:active{
    color: white;
}
.navbar-logo{
    background: transparent;
    border: none;
}
.navbar-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    width: 117px;
    height: 42px;
    background: #FFFFFF;
    border-radius: 55px;
    
    color: #000000;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    text-decoration: none;
}
.navbar-Respbtn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    width: 117px;
    height: 42px;
    background: #000000;
    border-radius: 55px;
    
    color: #FFFFFF;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    text-decoration: none;
    position: relative;
}
.hamburgerMenu{
    background-color: #000000 !important;
}
.hamburgerMenu-services .btn{
    color: #000000;
}

/* Extra small devices */
@media (min-width: 320px) {
    nav{
        padding: 10px 30px;
    }
    .desktopVOf-navbar{
        display: none !important;
    }
}

/* Small devices */
@media (min-width: 480px) {

}

/* Medium devices */
@media (min-width: 970px) {
    .desktopVOf-navbar{
        display: flex !important;
    }
    .responsiveVOf-navbar{
        display: none !important;
    }
}

/* Large devices */
@media (min-width: 992px) {
    nav{
        padding: 10px 30px;
    }
    .desktopVOf-navbar{
        display: flex;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    nav{
        padding: 10px 40px;
    }
}

@media (min-width: 1300px) {
    nav{
        padding: 10px 0px 10px 0;
    }
}

nav .dropdown .btn {
    border: none !important;
    box-shadow: none !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

nav .dropdown .btn:active,
nav .dropdown .btn:visited,
nav .dropdown .btn.show,
nav .dropdown .btn:focus,
nav .dropdown .btn:focus-visible,
nav .dropdown .btn:focus-within
{
    color: #ffffff !important;
    background-color: inherit !important;
    border: none !important;
    box-shadow: none !important;
}