#monthlySub{
    padding: 64px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 44px;
    margin-top: 44px;
    margin-bottom: 44px;
}
.monthlySub{
    width: 100%;
}
.monthlySub article{
    gap: 12px;
}
.monthlySub article p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #000000;
}
.monthlySub article h1{
    width: 270px;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #000000;
}
.sub-detail{
    padding: 44px;
    gap: 32px;
    width: 484px;
    height: 510px;
    background: url(../../../assets/images/MonthlySub-detailBG.png);
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #E4E4E4;
    border-radius: 20px;
}
.subDetail-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 396px;
    height: 118px;    
}
.sub-details-Infobtn{
    padding: 2px 10px;
    width: 102px;
    height: 30px;
    background: #E8EEFF;
    border-radius: 44px;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;    
    cursor: default !important;

    border: none;
}
.subDetail-header p{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 0;
}
.subDetail-header h2{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin: 0;
}
.sub-detail ul{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 396px;
    height: 198px;    
    list-style: none;
    position: relative;
}
.sub-detail ul li{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    position: relative;
    left: 32px;
}
.sub-detail ul li::before{
    content: url(../../../assets/images/blueCheckmark.svg);
    /* content: '✓'; */
    /* color: #5182FF; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 20px;
    height: 20px;
    /* background: #FFFFFF; */
    /* border: 1px solid #5182FF; */
    border-radius: 22px;
    position: absolute;
    left: -32px;
    top: 7px;
}
.sub-details-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    max-width: 396px;
    height: 42px;
    background: #FFFFFF;
    border-radius: 55px;
    border: none;
    color: #000000 !important;
}


/* Extra small devices */
@media (min-width: 320px) {
    .monthlySub{
        flex-direction: column !important;
        gap: 30px;
    }
    .sub-detail{
        width: auto;
        height: auto;
    }
    .subDetail-header{
        width: auto;
        height: auto;

    }
    .sub-detail ul{
        width: auto;
        height: auto;

    }
}

/* Medium devices */
@media (min-width: 768px) {
    .monthlySub{
        flex-direction: row !important;
        gap: 0;
    }
}

/* Extra large devices */
@media (min-width: 1200px) {
    .subDetail-header{
        width: 396px;
        height: 118px;
    }
    .sub-detail ul{
        width: 396px;
        height: auto;
    }
}
