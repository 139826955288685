#info-containerAbout{
    padding-top: 96px;
    display: flex;
    flex-direction: column;
    gap: 44px;
}
#info-container .info{
    gap: 44px;
}
.infoImg-container1About{
    background-image: url(../../../assets/images/InfoAbout-img1.png);
    width: 622px;
    height: 522px;
    border-radius: 44px;
    position: relative;
}
.infoAbout-article1{
    padding: 24px;
    gap: 16px;
    
    width: 494px;
    height: 356px;
}
.infoArticle-button{
    padding: 2px 10px;
    gap: 10px;
    width: 154px;
    height: 30px;
    background: #E8EEFF;
    border-radius: 44px;
    border: none;

    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;
}
.infoAbout-article1 h1{
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    width: 446px;
}
.infoAbout-article1 p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;    
    max-width: 446px;
    height: 130px;
}
.infoAbout-img1{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 24px;
    
    position: relative;
    left: 24px;
    top: 24px;
    
    width: 227px;
    height: 112px;
    background: #FFFFFF;
    border-radius: 32px;
}

.infoAbout-img1 h3{
    width: 91px;
    height: 32px;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;
}
.infoAbout-article2{
    padding: 24px;
    gap: 16px;
    
    width: 494px;
    height: 356px;
    text-align: end;
}
.infoAbout-article2 h1{
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    width: 446px;
}
.infoAbout-article2 p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;    
    max-width: 446px;
    height: 130px;
}
.infoAbout-img2{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 24px;
    
    position: absolute;
    right: 24px;
    bottom: 24px;
    
    width: 343px;
    height: 112px;
    background: #FFFFFF;
    border-radius: 32px;
}
.infoAbout-img2 h3{
    width: 207px;
    height: 64px;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;    
}
.infoImg-container2About{
    background-image: url(../../../assets/images/InfoAbout-img2.png);
    width: 622px;
    height: 522px;
    border-radius: 44px;
}

/* Extra small devices */
@media (min-width: 320px) {
    #info-containerAbout{
        gap: 0;
    }
    #info-containerAbout .info{
        justify-content: center !important;
    }
    .infoImg-container1About,.infoImg-container2About{
        display: none !important;
    }
    .infoAbout-article1, .infoAbout-article2{
        width: auto;
        height: auto;
    }
    .infoAbout-article1 h1, .infoAbout-article2 h1{
        width: auto;
    }
    .infoAbout-article1 p, .infoAbout-article2 p{
        height: auto;
    }
}
/* Large devices */
@media (min-width: 992px) {
    #info-containerAbout .info{
        gap: 44px;
        padding: 0 15px;
        justify-content: unset !important;
    }
    .infoImg-container1About,.infoImg-container2About{
        display: flex !important;
    }
    #info-containerAbout{
        gap: 44px;
    }
}