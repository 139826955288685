#team{
    padding: 30px 99px 20px 99px;
    gap: 44px;
    width: 1160px;
    height: 1368px;    
    margin-top: 44px;
}
#clinical-team{
    padding: 0 99px;
    gap: 44px;
    width: 1160px;
    height: 1368px;
}
#international-business-team{
    padding: 0 99px;
    gap: 44px;
    width: 1160px;
    height: 1368px;
}
#team .team-title, #clinical-team .team-title, #international-business-team .team-title{
    gap: 196px;
    width: 962px;
    height: 174px;
}
.team-title article p{
    width: 630px;
}
.team-title article p{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6D6D6D;
}
.team-title .team-more-btn{
    padding: 8px 12px;
    width: 383px;
    height: 42px;
    background: #000000;
    border-radius: 55px;
    border: none;
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;    
}
#team .member-bios, #clinical-team .member-bios, #international-business-team .member-bios{
    padding: 36px;
    gap: 36px;
    isolation: isolate;
    width: 962px;
    height: 322px;
    border: 1px solid #E4E4E4;
    border-radius: 24px;
    position: relative;
}
.bios-image-container{
    width: 201px;
    height: 250px;
    border-right: 1px solid #E4E4E4;
}
.member-bios article{
    max-width: 652px;
    height: 250;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #6D6D6D;    
}
.member-bios .member-bios-closingBtn{
    position: absolute;
    top: 11px;
    right: 14px;
    border: 1px solid #E4E4E4;
    border-radius: 40px;
    background-color: transparent;
    width: 30px;
    height: 30px;
}
.memberS-title{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;    
    width: 155.6px;
    height: 52px;
    
}
#team .member-card-container, #clinical-team .member-card-container, #international-business-team .member-card-container{
    gap: 16px;
    width: 962px;
    height: 288px;
}

#team .member-card-container h2, #clinical-team .member-card-container h2, #international-business-team .member-card-container h2{
    width: 100%;
    text-align: center;
    padding: 15px 0 15px 0;
}

#team .member-card-container .member-card, #clinical-team .member-card-container .member-card, #international-business-team .member-card-container .member-card{
    padding: 24px 12px;
    isolation: isolate;
    width: 179.6px;
    height: 288px;
    border: 1px solid #E4E4E4;
    border-radius: 24px;
    gap: 20px;

}
.member-img-container{
    padding: 0px;
    width: 155.6px;
    height: 178px;    
    gap: 16px;
}
.member-img-container h6{
    font-family: 'aeonik-500', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #101828;    
    margin-bottom: 4px;
}
.member-card-btn{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6D6D6D;    
    border: none;
    background: none;
    width: 155.6px;
    height: 43px;
    padding-top: 16px;
    border-top: 1px solid #E4E4E4;
}
.member-card-btn:active,.member-card-btn:visited,.member-card-btn:hover{
    font-family: 'aeonik-400', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5182FF;    
}
.member-card-img{
    width: 80px;
    height: 80px;
    border-radius: 40px;
}

/* Extra small devices */
@media (min-width: 320px) {
    #team{
        padding: 30px 20px 96px 20px;
        height: auto;
        width: auto;
    }
    #clinical-team{
        padding: 20px 20px;
        height: auto;
        width: auto;
    }
    #international-business-team{
        padding: 20px 20px;
        height: auto;
        width: auto;
    }
    #team .team-title, #clinical-team .team-title, #international-business-team .team-title{
        width: auto;
        height: auto;
    }
    .team-title article p{
        width: 100%;
    }
    #team .member-bios, #clinical-team .member-bios, #international-business-team .member-bios{
        flex-direction: column !important;
        width: 100%;
        height: auto;
    }
    .bios-image-container{
        border-right: none;
        border-bottom: 1px solid #E4E4E4;
    }
    .member-bios article{
        height: auto;
    }
    #team .member-card-container, #clinical-team .member-card-container, #international-business-team .member-card-container{
        width: auto;
        height: auto;
        flex-direction: column !important;
        align-items: center !important;
    }
}

/* Small devices */
@media (min-width: 480px) {
    #team .member-card-container, #clinical-team .member-card-container, #international-business-team .member-card-container{
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center !important;
    }
    /* #team .member-card-container .member-card{
        width: ; /* calc( 50% - 16px ) 
    } */

}

/* Medium devices */
@media (min-width: 768px) {
    .team-title article p {
        width: 630px;
    }
    #team .member-bios, #clinical-team .member-bios, #international-business-team .member-bios {
        flex-direction: row !important;
        height: auto;
    }
    .bios-image-container {
        border-bottom: none;
        border-right:  1px solid #E4E4E4;
    }
    
}

/* Large devices */
@media (min-width: 992px) {
    #team, #clinical-team, #international-business-team{
        padding: 30px 50px 96px 50px;
    }
    #team .member-bios, #clinical-team .member-bios, #international-business-team .member-bios{
        width: 962px;
        min-height: 322px;
    }   
}

/* Extra large devices */
@media (min-width: 1200px) {
    #team{
        padding: 30px 99px 50px 99px;
    }
    #clinical-team{
        padding: 50px 99px 96px 99px;
    }
    #international-business-team{
        padding: 50px 99px 96px 99px;
    }
}
