.modalBody{
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    flex-wrap: wrap;
}
.modalBodyImg{
    width: 120px;
    height: 120px;
    margin-right: 10px;
    align-self: flex-start;
}
.modalBodyArticle{
    width: 80%;
    display: flex;
    gap: 10px;
}
.modalBodyP{
    flex: 1 !important;
    margin: 0 !important;
    justify-content: stretch;
    width: 100% !important;
}
.startBtn{
    width: 70px !important;
    height: 40px !important;
    font-size: 19px !important;
}
.startBtn:hover{
    color: white;
}