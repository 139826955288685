.bookLocation {
    padding-top: 150px;
    padding-bottom: 150px;
}
.bookLocationLeft {
    border-right: 1px #d9dbdd solid;
}

.bookLocationRight {
    padding-top: 50px;
}

.locationNameRow {
    padding-top: 10px;
}
.bookLocation .form-control, .bookLocation .form-select, .btn-dark {
    max-width: 80%;
}

.bookLocationRight ul {
    padding: 20px 0 0 0;
    margin: 0;
    list-style: none;
}

.bookLocationRight ul li {
    padding: 10px 0;
    clear: both;
}

.bookLocationRight ul li .image {
    padding-right: 10px;
    display: inline-block;
    float: left;
    width: 8%;
}

.bookLocationRight ul li .text {
    margin-top: 2px;
    display: inline-block;
    float: left;
    width: 92%;
}

.speakSection {
    padding: 10px;
    border: 1px #d9dbdd solid;
    margin-top: 30px;
    float: left;
    width: 100%;
}

.speakSection .image {
    float: left;
    padding-right: 15px;
}

.speakSection .text {
    float: left;
}

.speakSection .text .title {
    font-size: 18px;
    font-weight: bold;
}
.invalidFeedbackError {
    color: #842029;
    font-size: 14px;
}